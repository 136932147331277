import React from 'react';
import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';
import FindYourHappy from 'assets/svgs/find_your_happy.svg';
import StressLess from 'assets/svgs/stress_less.svg';
import SleepBetter from 'assets/svgs/sleep_better.svg';

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightSand,
  backgroundDark: props.theme.colors.backgroundDark,
  padding: `60px 104px 102px 104px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [BREAKPOINTS[800]]: {
    padding: `74px 4px`,
  },
}));

const Title = styled.div({
  fontFamily: 'Taviraj',
  fontWeight: 700,
  fontSize: 45,
  lineHeight: '52px',
  letterSpacing: 0.2,
  textAlign: 'center',
  marginBottom: 12,
  margin: `0 16px 12px 16px`,
  [BREAKPOINTS[800]]: {
    fontSize: 30,
    lineHeight: '45px',
  },
});

const Subtitle = styled.div({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'center',
  maxWidth: 640,
  margin: `0 16px 12px 16px`,
});

const StepsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 44,
  [BREAKPOINTS[955]]: {
    flexDirection: 'column',
  },
  [BREAKPOINTS[440]]: {
    alignItems: 'stretch',
  },
});

const StepPaddingWrapper = styled.div({
  display: 'flex',
  flexBasis: '33%',
  margin: '16px',
  [BREAKPOINTS[440]]: {
    margin: '16px 0',
  },
});

const Dash = styled.div((props) => ({
  height: 1,
  width: 54,
  borderBottom: `1px solid ${props.theme.colors.backgroundDark}`,
  marginTop: 42,
}));

const Step = styled.div((props) => ({
  backgroundColor: props.theme.colors.semiDarkSand,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 32px',
  borderRadius: 10,
  height: 380,
  [BREAKPOINTS[955]]: {
    width: 296,
  },
  [BREAKPOINTS[440]]: {
    width: 'unset',
    flex: 1,
    height: 274,
  },
}));

const StepTitle = styled.div((props) => ({
  fontFamily: 'Taviraj',
  color: props.theme.colors.black,
  fontWeight: 700,
  fontSize: 28,
  lineHeight: '42px',
  letterSpacing: 0.2,
  marginBottom: 18,
  marginTop: 22,
}));

const StepDescription = styled.div((props) => ({
  color: props.theme.colors.black,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.3,
}));

const Instructions: React.FC = () => (
  <Container>
    <Title>Take better care of yourself</Title>
    <Subtitle>
      If you want to relieve stress, learn yoga, improve your sleep, or just simply relax, you will
      find it all in the app.
    </Subtitle>
    <Dash />
    <StepsContainer>
      <StepPaddingWrapper>
        <Step>
          <FindYourHappy />
          <StepTitle>Find Your Happy</StepTitle>
          <StepDescription>
            I know you`re busy and stressed, but it only takes a few minutes. Do something for
            yourself or loved ones.
          </StepDescription>
        </Step>
      </StepPaddingWrapper>
      <StepPaddingWrapper>
        <Step>
          <StressLess />
          <StepTitle>Stress Less</StepTitle>
          <StepDescription>
            Unplug from the stressors in life and take a break. Relax your mind and body - they need
            it!
          </StepDescription>
        </Step>
      </StepPaddingWrapper>
      <StepPaddingWrapper>
        <Step>
          <SleepBetter />
          <StepTitle>Sleep Better</StepTitle>
          <StepDescription>
            Get a good night`s sleep - put your mind at ease and get some rest for the next day!
          </StepDescription>
        </Step>
      </StepPaddingWrapper>
    </StepsContainer>
  </Container>
);

export default Instructions;
