import React from 'react';
import { graphql } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from '../utils/constants';

import SEO from 'components/SEO';
import Header from 'components/DealPageHeader';
import Hero from 'components/DealPageComponents/Hero';
import Instructions from 'components/DealPageComponents/Instructions';
import ContentInfo from 'components/DealPageComponents/ContentInfo';
import ComparisonTable from 'components/DealPageComponents/ComparisonTable';
import PlanSelect from 'components/DealPageComponents/PlanSelect';
import Footer from 'components/DealPageComponents/Footer';

export const query = graphql`
  query AffiliateLandingQuery($id: String) {
    prismicAffiliateLanding(id: { eq: $id }) {
      data {
        coupon
        deal_name
        discount_percentage
        deal_photo {
          alt
          copyright
          gatsbyImageData
          url
        }
        twitter_description {
          text
          html
          raw
        }
        twitter_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        twitter_title {
          text
          html
          raw
        }
        og_description {
          text
          html
          raw
        }
        og_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        og_title {
          text
          html
          raw
        }
        page_description {
          text
          html
          raw
        }
        page_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        page_title {
          text
          html
          raw
        }
      }
    }
  }
`;

const Container = styled.div({
  '&& > *': {
    paddingLeft: `calc((100% - min(1200px, calc(100% - ${WEB_SIDE_PADDING} * 2))) / 2)`,
    paddingRight: `calc((100% - min(1200px, calc(100% - ${WEB_SIDE_PADDING} * 2))) / 2)`,
    [BREAKPOINTS[800]]: {
      paddingLeft: MOBILE_SIDE_PADDING,
      paddingRight: MOBILE_SIDE_PADDING,
    },
  },
});

const AffiliateLanding = ({ data }) => {
  const landingData = data.prismicAffiliateLanding.data;
  const discountPercents = parseInt(landingData.discount_percentage, 10);

  return (
    <Container>
      <SEO
        pageTitle={landingData?.page_title?.text}
        ogTitle={landingData?.og_title?.text}
        twitterTitle={landingData?.twitter_title?.text}
        pageDescription={landingData?.page_description?.text}
        ogDescription={landingData?.og_description?.text}
        twitterDescription={landingData?.twitter_description?.text}
        pageImage={landingData?.page_image?.url}
        pageImageAlt={landingData?.page_image?.alt}
        twitterImage={landingData?.twitter_image?.url}
        twitterImageAlt={landingData?.twitter_image?.alt}
        ogImage={landingData?.og_image?.url}
        ogImageAlt={landingData?.og_image?.url?.alt}
        noindex
      />
      <Header />
      <Hero
        dealName={landingData.deal_name}
        discountPercentage={discountPercents}
        coupon={landingData.coupon}
        image={getImage(landingData.deal_photo) as IGatsbyImageData}
      />
      <Instructions />
      <ContentInfo coupon={landingData.coupon} />
      <ComparisonTable coupon={landingData.coupon} discountPercentage={discountPercents} />
      <PlanSelect coupon={landingData.coupon} discountPercentage={discountPercents} />
      <Footer />
    </Container>
  );
};

export default AffiliateLanding;
