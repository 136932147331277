import React from 'react';
import styled from '@emotion/styled';

interface ILockedPlan {
  planName: string;
  pricePerMonth: string;
}

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightSand,
  borderRadius: 20,
  width: 306,
  height: 166,
  textAlign: 'center',
  paddingTop: 46,
}));

const Dash = styled.div({
  margin: '16px 44px',
  borderBottom: '1px solid rgba(213, 213, 213, 0.5)',
});

const PlanName = styled.div((props) => ({
  color: props.theme.colors.backgroundDark,
  fontFamily: 'Taviraj',
  fontWeight: 700,
  fontSize: 30,
  lineHeight: '50px',
}));

const Price = styled.div({
  color: '#4D4D4D',
});

const PriceText = styled.span({
  lineHeight: '26px',
  fontSize: 18,
});

const PeriodText = styled.span({
  opacity: 0.5,
});

const Dollar = styled.span({
  verticalAlign: 'top',
  marginRight: 2,
  fontSize: 14,
});

const LockedPlan: React.FC<ILockedPlan> = ({ planName, pricePerMonth }) => (
  <Container>
    <PlanName>{planName}</PlanName>
    <Dash />
    <Price>
      <Dollar>&#36;</Dollar>
      <PriceText>
        {pricePerMonth} <PeriodText> / month</PeriodText>
      </PriceText>
    </Price>
  </Container>
);

export default LockedPlan;
