import React from 'react';
import styled from '@emotion/styled';

import Button from './DiscountButton';

import Checkmark from 'assets/svgs/gray_checkmark.svg';
import { BREAKPOINTS } from 'utils/constants';

interface IExclusivePlan {
  discountPercentage: number;
  coupon: string;
}

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.backgroundDark,
  borderRadius: 20,
  width: 246,
  padding: '28px 30px 42px 30px',
  textAlign: 'center',
  margin: '0 42px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [BREAKPOINTS[1200]]: {
    margin: '14px 0',
  },
}));

const Dash = styled.div({
  margin: '20px 0 30px 0',
  borderBottom: '1px solid rgba(213, 213, 213, 0.2)',
  width: '100%',
});

const PlanName = styled.div((props) => ({
  color: props.theme.colors.white,
  fontFamily: 'Taviraj',
  fontWeight: 800,
  fontSize: 28,
  lineHeight: '48px',
}));

const Price = styled.div((props) => ({
  color: props.theme.colors.white,
}));

const PriceText = styled.span({
  lineHeight: '56px',
  fontSize: 40,
});

const PeriodText = styled.span({
  fontSize: 14,
  opacity: 0.5,
});

const Dollar = styled.span({
  verticalAlign: 'top',
  marginRight: 2,
  fontSize: 20,
});

const PricePerYear = styled.div({
  color: '#5E7FE5',
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.2,
  textAlign: 'center',
});

const LineThrought = styled.span({
  textDecoration: 'line-through',
});

const PricePerYearAfterDiscount = styled.span((props) => ({
  color: props.theme.colors.white,
  opacity: 0.5,
  textDecoration: 'none',
}));

const DiscountBadge = styled.div((props) => ({
  backgroundColor: props.theme.colors.dealPageBlue,
  color: props.theme.colors.white,
  padding: '7px 11px',
  fontSize: '10px',
  borderRadius: 5,
  marginBottom: 24,
  fontWeight: 800,
  maxWidth: 77,
}));

const StyledButton = styled(Button)((props) => ({
  backgroundColor: props.theme.colors.dealPageBlue,
  fontSize: 13,
  color: props.theme.colors.white,
  fontWeight: 'bold',
  padding: '10px 0',
  marginTop: '42px',
  marginBottom: '14px',
  width: '100%',
  ':hover': {
    backgroundColor: props.theme.colors.dealPageBlue,
    opacity: 0.8,
  },
}));

const GuarantyTexts = styled.div({
  color: 'rgba(255, 255, 255, 0.6)',
  fontSize: 12,
  lineHeight: '18px',
  letterSpacing: 0.2,
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr',
  alignItems: 'center',
  justifyItems: 'start',
  columnGap: 5,
  rowGap: 8,
  margin: '0 auto 0 0',
});

const ExclusivePlan: React.FC<IExclusivePlan> = ({ discountPercentage, coupon }) => {
  const priceAfterDiscount = 120 - (120 * discountPercentage) / 100;
  const pricePerMonth = priceAfterDiscount / 12;

  return (
    <Container>
      <DiscountBadge>SAVE {discountPercentage}%</DiscountBadge>
      <PlanName>Exclusive offer</PlanName>
      <Dash />
      <Price>
        <Dollar>&#36;</Dollar>
        <PriceText>
          {pricePerMonth.toFixed(2)} <PeriodText> / month</PeriodText>
        </PriceText>
      </Price>
      <PricePerYear>
        <LineThrought>$120.00</LineThrought>
        <PricePerYearAfterDiscount>
          {' $'}
          {priceAfterDiscount.toFixed(2)} annually
        </PricePerYearAfterDiscount>
      </PricePerYear>
      <StyledButton coupon={coupon} />
      <GuarantyTexts>
        <Checkmark width={14} height={14} /> 30-day money back guarantee
        <Checkmark width={14} height={14} /> Includes a 7-day free trial
      </GuarantyTexts>
    </Container>
  );
};

export default ExclusivePlan;
