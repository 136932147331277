import React from 'react';
import styled from '@emotion/styled';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';

import DiscountButton from './DiscountButton';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const DesktopContainer = styled.div({
  display: 'contents',
  [BREAKPOINTS[800]]: { display: 'none' },
});
const MobileContainer = styled.div({
  display: 'none',
  [BREAKPOINTS[800]]: { display: 'contents' },
});

const Container = styled.div({
  background: 'radial-gradient(106.81% 97.25% at 83.33% 37.85%, #71778E 0%, #000000 100%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0 ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: `100px ${MOBILE_SIDE_PADDING} 50px ${MOBILE_SIDE_PADDING}`,
  },
});

const DesktopTextsContainer = styled.div({
  maxWidth: 750,
  marginRight: 12,
});

const Title = styled.div((props) => ({
  color: props.theme.colors.lightSand,
  fontFamily: 'Taviraj',
  fontSize: 48,
  lineHeight: '66px',
  fontWeight: 'bold',
  letterSpacing: '0.01em',
  [BREAKPOINTS[1150]]: {
    fontSize: 36,
    lineHeight: '50px',
  },
  [BREAKPOINTS[800]]: {
    textAlign: 'center',
  },
}));

const DealDetails = styled.div((props) => ({
  color: props.theme.colors.lightSand,
  fontWeight: 'bold',
  fontSize: 16,
  marginTop: 32,
  marginBottom: 56,
  padding: '12px 20px',
  backgroundColor: 'rgba(255, 249, 242, 0.14)',
  borderRadius: 10,
  display: 'inline-block',
  [BREAKPOINTS[800]]: {
    marginBottom: 24,
  },
}));

const LowOpacity = styled.span({
  opacity: 0.6,
  fontWeight: 200,
});

const StyledButton = styled(DiscountButton)({
  display: 'block',
  width: 'max-content',
});

const StyledGatsbyImage = styled(GatsbyImage)({
  height: 500,
  width: 500,
  minHeight: 500,
  minWidth: 500,
  borderRadius: 250,
  margin: '85px 0',
  [BREAKPOINTS[1150]]: {
    height: 400,
    width: 400,
    minHeight: 400,
    minWidth: 400,
    borderRadius: 200,
  },
  [BREAKPOINTS[1150]]: {
    height: 320,
    width: 320,
    minHeight: 320,
    minWidth: 320,
    borderRadius: 160,
  },
  [BREAKPOINTS[800]]: {
    height: 270,
    width: 270,
    minHeight: 270,
    minWidth: 270,
    borderRadius: 185,
    margin: '32px 0',
  },
});

interface IHero {
  dealName: string;
  discountPercentage: number;
  coupon: string;
  image: IGatsbyImageData;
}

const MobileHero: React.FC<IHero> = ({ dealName, discountPercentage, coupon, image }) => (
  <MobileContainer>
    <Container>
      <Title>{dealName}</Title>
      <DealDetails>
        Exclusive offer: save {discountPercentage}% <LowOpacity>on Yours App yearly.</LowOpacity>
      </DealDetails>
      <DiscountButton coupon={coupon} />
      <StyledGatsbyImage image={image} alt={dealName} />
    </Container>
  </MobileContainer>
);

const DesktopHero: React.FC<IHero> = ({ dealName, discountPercentage, coupon, image }) => (
  <DesktopContainer>
    <Container>
      <DesktopTextsContainer>
        <Title>{dealName}</Title>
        <DealDetails>
          Exclusive offer: save {discountPercentage}% <LowOpacity>on Yours App yearly.</LowOpacity>
        </DealDetails>
        <StyledButton coupon={coupon} />
      </DesktopTextsContainer>
      <StyledGatsbyImage image={image} alt={dealName} />
    </Container>
  </DesktopContainer>
);

const Hero: React.FC<IHero> = (props) => {
  return (
    <>
      <DesktopHero {...props} />
      <MobileHero {...props} />
    </>
  );
};

export default Hero;
