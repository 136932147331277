import React from 'react';
import styled from '@emotion/styled';

import LockedPlan from './LockedPlan';
import ExclusivePlan from './ExclusivePlan';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

interface IPlanSelect {
  coupon: string;
  discountPercentage: number;
}

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.darkSand,
  padding: `100px ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    padding: `65px ${MOBILE_SIDE_PADDING} 40px ${MOBILE_SIDE_PADDING}`,
  },
}));

const Title = styled.div((props) => ({
  color: props.theme.colors.darkText,
  fontFamily: 'Taviraj',
  fontWeight: 900,
  fontSize: 45,
  lineHeight: '56px',
  letterSpacing: 0.2,
  textAlign: 'center',
  [BREAKPOINTS[800]]: {
    fontSize: 30,
    lineHeight: '45px',
  },
}));

const Subtitle = styled.div((props) => ({
  color: props.theme.colors.darkText,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '21px',
  textAlign: 'center',
  marginTop: 16,
  marginBottom: 60,
}));

const PlansContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [BREAKPOINTS[1200]]: {
    flexDirection: 'column',
  },
});

const PlanSelect: React.FC<IPlanSelect> = ({ coupon, discountPercentage }) => (
  <Container>
    <Title>Save {discountPercentage}% on Yours App</Title>
    <Subtitle>300+ sleep stories, guided meditations, music & sounds</Subtitle>
    <PlansContainer>
      <LockedPlan planName="Monthly plan" pricePerMonth="10.00" />
      <ExclusivePlan discountPercentage={discountPercentage} coupon={coupon} />
      <LockedPlan planName="Yearly plan" pricePerMonth="5.00" />
    </PlansContainer>
  </Container>
);

export default PlanSelect;
