import React from 'react';
import styled from '@emotion/styled';

import Button from './DiscountButton';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';
import Checkmark from 'assets/svgs/green_checkmark.svg';

interface IComparisonTable {
  coupon: string;
  discountPercentage: number;
}

interface IIsDarkBackground {
  isDarkBackground: boolean;
}

interface IIsLastProps {
  isLast: boolean;
}

const features = [
  {
    feature: 'Yoga',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Meditation',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Sleep',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Breathing clocks',
    isBasicUnlimited: true,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Expert psychology advice',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Fitness',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Content for children',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'Content for parents',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
  {
    feature: 'LGBTQI+ themes',
    isBasicUnlimited: false,
    isPremiumUnlimited: true,
  },
];

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightSand,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'auto',
  padding: `115px ${WEB_SIDE_PADDING} 0 ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    padding: `40px ${MOBILE_SIDE_PADDING} 53px ${MOBILE_SIDE_PADDING}`,
  },
}));

const FeatureRow = styled.tr<IIsDarkBackground>((props) => ({
  backgroundColor: props.isDarkBackground ? props.theme.colors.lightSand : props.theme.colors.white,
}));

const Header = styled.tr((props) => ({
  backgroundColor: props.theme.colors.darkGray,
  borderCollapse: 'collapse',
}));

const StyledTable = styled.table({
  borderCollapse: 'collapse',
  borderRadius: 10,
  borderStyle: 'hidden',
  boxShadow: '0px 2.06947px 50px rgba(0, 0, 0, 0.12)',
  tableLayout: 'fixed',
});

const HeaderCell = styled.th({
  padding: '22px 40px',
  fontWeight: 700,
});

const FeatureHeaderCell = styled(HeaderCell)({
  borderTopLeftRadius: 10,
});

const PremiumHeaderCell = styled(HeaderCell)((props) => ({
  color: props.theme.colors.white,
  backgroundColor: props.theme.colors.semiLightGray,
  borderTopRightRadius: 10,
  width: 100,
}));

const BasicHeaderCell = styled(HeaderCell)({
  color: 'rgba(255, 255, 255, 0.5)',
  width: 100,
});

const FeatureCell = styled.td({
  padding: '22px 35px',
  fontWeight: 100,
  width: 100,
});

const FeatureTextCell = styled(FeatureCell)<IIsLastProps>((props) => ({
  textAlign: 'left',
  width: '70%',
  fontWeight: 700,
  borderBottomLeftRadius: props.isLast ? 10 : 0,
}));

const ClaimDiscountOffer = styled.div((props) => ({
  color: props.theme.colors.darkGray,
  marginRight: 30,
}));

const ClaimDiscountContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 95,
  alignItems: 'center',
});

const ClaimDiscountBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: '0px 2.06947px 50px rgba(0, 0, 0, 0.12)',
  width: 150,
  minWidth: 150,
  padding: 25,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
});

const ClaimDiscountText = styled.div((props) => ({
  fontWeight: 400,
  color: props.theme.colors.darkText,
  textDecoration: 'line-through',
  fontSize: 18,
}));

const NewPriceText = styled.div({
  fontWeight: 700,
  textDecoration: 'none',
  marginTop: 12,
});

const StyledButton = styled(Button)((props) => ({
  backgroundColor: props.theme.colors.dealPageBlue,
  fontSize: 12,
  color: props.theme.colors.white,
  fontWeight: 'bold',
  padding: '7px 9px',
  marginTop: 12,
  ':hover': {
    backgroundColor: props.theme.colors.dealPageBlue,
    opacity: 0.8,
  },
}));

const Title = styled.div((props) => ({
  color: props.theme.colors.darkText,
  fontFamily: 'Taviraj',
  fontWeight: 900,
  fontSize: 45,
  lineHeight: '56px',
  letterSpacing: 0.2,
  textAlign: 'center',
  [BREAKPOINTS[800]]: {
    fontSize: 30,
    lineHeight: '45px',
  },
}));

const Subtitle = styled.div((props) => ({
  color: props.theme.colors.darkText,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '21px',
  textAlign: 'center',
  marginTop: 16,
  marginBottom: 60,
}));

const ComparisonTable: React.FC<IComparisonTable> = ({ coupon, discountPercentage }) => (
  <Container>
    <Title>Unlock everything with Yours Premium</Title>
    <Subtitle>
      Curate your perfect self-care routine with our best in class content recommendations
    </Subtitle>
    <StyledTable>
      <thead>
        <Header>
          <FeatureHeaderCell>&nbsp;</FeatureHeaderCell>
          <BasicHeaderCell>Basic</BasicHeaderCell>
          <PremiumHeaderCell>Premium</PremiumHeaderCell>
        </Header>
      </thead>
      <tbody>
        {features.map((feature, index) => (
          <FeatureRow key={feature.feature} isDarkBackground={index % 2 !== 0}>
            <FeatureTextCell isLast={index === features.length - 1}>
              {feature.feature}
            </FeatureTextCell>
            <FeatureCell>{feature.isBasicUnlimited ? <Checkmark /> : 'Limited'}</FeatureCell>
            <FeatureCell>{feature.isPremiumUnlimited ? <Checkmark /> : 'Limited'}</FeatureCell>
          </FeatureRow>
        ))}
      </tbody>
    </StyledTable>
    <ClaimDiscountContainer>
      <ClaimDiscountOffer>With this exclusive offer:</ClaimDiscountOffer>
      <ClaimDiscountBox>
        <ClaimDiscountText>$120.00</ClaimDiscountText>
        <NewPriceText>${(120 - (120 * discountPercentage) / 100).toFixed(2)}</NewPriceText>
        <StyledButton coupon={coupon} />
      </ClaimDiscountBox>
    </ClaimDiscountContainer>
  </Container>
);

export default ComparisonTable;
