import React from 'react';
import styled from '@emotion/styled';

import DiscountButton from './DiscountButton';

import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';
import BreathingClock from 'assets/svgs/breathing_clock_content.svg';
import EasyYoga from 'assets/svgs/easy_yoga_content.svg';
import ManageStress from 'assets/svgs/manage_stress_content.svg';
import MasterYourMind from 'assets/svgs/master_your_mind_content.svg';
import Music from 'assets/svgs/music_content.svg';
import SleepBetter from 'assets/svgs/sleep_better_content.svg';
import MobilePhones from 'assets/others/mobile_phones_clocks.png';

interface IContentInfo {
  coupon: string;
}

const data = [
  {
    icon: <BreathingClock />,
    title: 'Breathing clocks',
    description: 'Interactive breathing techniques for focus, empowerment, relaxation & more',
  },
  {
    icon: <EasyYoga />,
    title: 'Easy yoga',
    description: 'Learn with courses, techniques and advice for all difficulty levels',
  },
  {
    icon: <ManageStress />,
    title: 'Manage stress & anxiety',
    description: '150+ meditations for adults, teens, children, pre & post-natal',
  },
  {
    icon: <MasterYourMind />,
    title: 'Master your mind',
    description:
      'Examine & nourish your thoughts and feelings with expert psychological guidance and mindfulness aid',
  },
  {
    icon: <Music />,
    title: 'Music for relaxation',
    description:
      'Expanding library of lo-fi, ambient, and piano music, atmospheres, binaural, ASMR, and sounds of nature',
  },
  {
    icon: <SleepBetter />,
    title: 'Sleep better',
    description: '50+ sleep stories to help you lull into deep sleep',
  },
];

const Container = styled.div({
  padding: `107px ${WEB_SIDE_PADDING}`,
  background: 'radial-gradient(52.28% 77.01% at 86.6% -12.69%, #787878 0%, #091F24 100%)',
  display: 'flex',
  justifyContent: 'space-between',
  [BREAKPOINTS[800]]: {
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: `107px ${MOBILE_SIDE_PADDING}`,
  },
});

const MobilePhoneImg = styled.div({
  backgroundImage: `url(${MobilePhones})`,
  backgroundSize: '100% auto',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: 400,
  [BREAKPOINTS[1200]]: {
    display: 'none',
  },
});

const Title = styled.div((props) => ({
  fontFamily: 'Taviraj',
  fontWeight: 900,
  fontSize: 40,
  lineHeight: '56px',
  letterSpacing: '0.2px',
  color: props.theme.colors.lightSand,
  marginBottom: 24,
  maxWidth: 700,
  [BREAKPOINTS[800]]: {
    fontSize: 30,
    lineHeight: '45px',
  },
}));

const ContentUnitsContainer = styled.div`
  display: grid;
  grid-template-columns: 272px 272px;
  grid-template-rows: 110px 110px 110px;
  gap: 24px 12px;
  grid-template-areas:
    '. .'
    '. .'
    '. .';
  ${BREAKPOINTS[800]} {
    display: block;
  }
`;

const ContentUnit = styled.div({
  display: 'flex',
  alignItems: 'center',
  [BREAKPOINTS[800]]: {
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
});

const IconContainer = styled.div({
  width: 56,
  marginRight: 12,
});

const ContentUnitTitle = styled.div((props) => ({
  fontSize: 16,
  lineHeight: '24px',
  color: props.theme.colors.white,
  letterSpacing: 0.3,
}));

const ContentUnitDescription = styled.div((props) => ({
  fontSize: 10,
  lineHeight: '15px',
  color: props.theme.colors.white,
  opacity: 0.7,
  letterSpacing: 0.3,
  [BREAKPOINTS[800]]: {
    maxWidth: 300,
  },
}));

const StyledButton = styled(DiscountButton)({
  marginTop: 60,
  [BREAKPOINTS[800]]: {
    width: '100%',
    maxWidth: 240,
  },
});

const ContentInfo: React.FC<IContentInfo> = ({ coupon }) => (
  <Container>
    <div>
      <Title>A large library of ever-expanding self-care content:</Title>
      <ContentUnitsContainer>
        {data.map((contentUnit) => (
          <ContentUnit key={contentUnit.title}>
            <IconContainer>{contentUnit.icon}</IconContainer>
            <div>
              <ContentUnitTitle>{contentUnit.title}</ContentUnitTitle>
              <ContentUnitDescription>{contentUnit.description}</ContentUnitDescription>
            </div>
          </ContentUnit>
        ))}
      </ContentUnitsContainer>
      <StyledButton coupon={coupon} text="Get Yours Premium" />
    </div>
    <MobilePhoneImg />
  </Container>
);

export default ContentInfo;
