import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Logo from 'assets/svgs/logo.svg';
import LogoDark from 'assets/svgs/logo_dark.svg';
import {
  INTERNAL_LINKS,
  WEB_SIDE_PADDING,
  BREAKPOINTS,
  MOBILE_SIDE_PADDING,
} from 'utils/constants';

interface IContainer {
  isScrollMenu: boolean;
}

const Container = styled.div<IContainer>((props) => ({
  display: 'flex',
  position: 'fixed',
  alignItems: 'center',
  padding: `32px ${WEB_SIDE_PADDING}`,
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: `rgba(57, 108, 216, 0)`,
  zIndex: 10,
  transition: 'all 0.3s ease-out',
  backdropFilter: 'blur(0)',
  [BREAKPOINTS[800]]: {
    paddingLeft: MOBILE_SIDE_PADDING,
    paddingRight: MOBILE_SIDE_PADDING,
  },
  ...(props.isScrollMenu && {
    backgroundColor: props.theme.colors.lightSand,
    backdropFilter: 'blur(20px)',
    paddingTop: 8,
    paddingBottom: 8,
    transition: 'all 0.5s ease-out',
  }),
}));

const LogoContainer = styled(Link)({
  textDecoration: 'none',
});

const StyledLogo = styled(Logo)({
  [BREAKPOINTS[800]]: {
    height: 40,
  },
});

const StyledDarkLogo = styled(LogoDark)({
  [BREAKPOINTS[800]]: {
    height: 40,
  },
});

const Header: React.FC = () => {
  const [isScrollMenu, setScrollMenu] = useState<boolean>(false);

  const scrollListener = () => {
    const scrollNumber = window.scrollY;

    if (scrollNumber > 15) {
      setScrollMenu(true);
    } else {
      setScrollMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <>
      <Container isScrollMenu={isScrollMenu}>
        <LogoContainer to={INTERNAL_LINKS.HOME}>
          {isScrollMenu ? <StyledDarkLogo /> : <StyledLogo />}
        </LogoContainer>
      </Container>
    </>
  );
};

export default Header;
